










































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: false }) isNotExistNft
  @Prop({ default: 1 }) itemPerRow
  @Prop({ default: 3 }) gapHorizontal
  @Prop({ default: 6 }) gapVertical
  @Prop({ default: () => [] }) nfts

  @Prop({ default: true }) showCode
  @Prop({ default: false }) showAttached
  @Prop({ default: false }) showAttach
  @Prop({ default: false }) showDetach
  @Prop({ default: false }) showAttributeLevel
  @Prop({ default: false }) showActiveNft
  @Prop({ default: false }) showNftType
  @Prop({ default: 'darken1' }) cardBackground
  @Prop({ default: false }) showAllItems
  @Prop({ default: true }) showSilhouette

  get totalRows() {
    return Math.ceil(this.nfts.length / this.itemPerRow)
  }
  decorators = []

  popoStore = popoStore
}
